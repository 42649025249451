import React from 'react';
import Link from 'next/link';
import { MdCall } from "react-icons/md";


const HeaderSection = ({ header,paragraph, breadCrumbUrl,breadCrumbText,breadCrumbAfter,breadCrumbAfterURL, phoneNumber, breadCrumbThirdURL, breadCrumbThirdText, breadCrumbFourthURL, breadCrumbFourthText }) => {
  return (
    <div className="bg-blue-50 py-10">
      <div className="container mx-auto px-4">
        <div className="text-sm text-blue-600 mb-4">
          <Link href={breadCrumbUrl}>{breadCrumbText}</Link> 
          {breadCrumbAfter && (<> &gt; <Link href={breadCrumbAfterURL}>{breadCrumbAfter}</Link> </> )}
          {breadCrumbThirdURL && (<> &gt; <Link href={breadCrumbThirdURL}>{breadCrumbThirdText}</Link> </> )}
          {breadCrumbFourthURL && (<> &gt; <Link href={breadCrumbFourthURL}>{breadCrumbFourthText}</Link> </> )}
        </div>
        <div className="flex flex-col items-center justify-center">
        <h1 className="text-4xl text-center font-bold text-gray-800 mb-6">
          {header}
        </h1>
        <p className='md:w-1/2 sm:w-full mb-6 text-center'>{paragraph}</p>
          {phoneNumber && <a
            href={`tel:${phoneNumber}`}
            className="bg-blue-500 text-white text-lg font-semibold px-6 py-3 rounded-full flex items-center space-x-2 hover:bg-blue-600"
          >
            <MdCall />
            <span>{phoneNumber}</span>
          </a>}
        </div>
      </div>
    </div>
  );
};

export default HeaderSection;
