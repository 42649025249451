import React from 'react';
import Link from 'next/link';

const SingleCard = ({ rank, title, rating = false, reviewCount, address, phone,newTab = '_blank', imageUrl, reviewText, mapUrl, hours, titleUrl,ratingCount,checkMap, facebook, instagram, twitter }) => {
  return (
    <div className="bg-white p-4 rounded-lg border-[1px] shadow-md flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mb-4">
    {/* Image Section */}
    <div className="w-full md:w-[20%]">
      <figure className="h-[168px] lg:mr-2 relative">
        <img src={imageUrl} alt={title} className="w-full h-full object-cover rounded-md" />
      </figure>
    </div>
  
    {/* Content Section */}
    <div className="w-full md:w-[80%]">
      <Link href={titleUrl}>
        <h2 className="text-xl font-bold text-blue-600">{title}</h2>
      </Link>
  
      {/* Rating and Review Section */}
      <div className="flex flex-wrap gap-4 mt-2">
        <p className="text-gray-600">{ratingCount} reviews</p>
        {rating && (
          <div className="flex items-center space-x-1 text-yellow-500">
            {[...Array(Math.floor(rating))].map((_, i) => (
              <svg key={i} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor" className="w-3 h-3">
                <path d="M12 .587l3.668 7.431 8.332 1.151-6.064 5.877 1.516 8.25L12 18.9l-7.452 4.396 1.516-8.25-6.064-5.877 8.332-1.151z" />
              </svg>
            ))}
          </div>
        )}
        <p className="text-gray-600">{reviewCount} reviews</p>
        {mapUrl && (
        <p className="flex items-center text-gray-600">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-4 h-4 mr-1">
            <path d="M19.428 15.304a8.5 8.5 0 00-6.303-10.608 8.501 8.501 0 00-10.607 6.303 8.502 8.502 0 006.304 10.608 8.503 8.503 0 0010.606-6.303z" />
            <path d="M12 7v5l3 3" />
          </svg>
            <Link href={mapUrl}>Check map</Link>
        </p>
        )}
      </div>
  
      {/* Address and Phone Section */}
      {address && <p className="text-gray-600 mt-2"><strong>Address:</strong> {address}</p>}
     {phone && <p className="text-gray-600"><strong>Phone:</strong> {phone}</p>}
      <p className="text-gray-600 mt-2">{reviewText}</p>
  
      {/* Call and Social Section */}
      <div className="flex justify-between flex-col sm:flex-row items-center mt-4 space-y-4 sm:space-y-0">
        <div className={`flex flex-wrap gap-2 lg:flex-nowrap ${checkMap || hours ? 'w-full flex-row' : "sm:w-full w-full lg:w-1/2"}`}>
          {hours && <div>{hours}</div>}
          {phone && <a href={`tel:${phone}`} className={`bg-blue-500 text-white py-2 px-4 text-center rounded-full ${checkMap || hours ? 'sm:w-full lg:w-1/3' : "sm:w-full lg:w-1/2"}`}>Call Now</a>}
          {checkMap && (
            <Link href={checkMap}  target={newTab} className={`bg-gray-200 text-gray-800 py-2 px-4 rounded-full text-center ${checkMap || hours ? 'sm:w-full lg:w-1/3' : "sm:w-full lg:w-1/2"}`}>Check Details</Link>
          )}
        </div>
  
        {/* Social Media Icons */}
        <div className="flex gap-2 w-full sm:w-full sm:justify-end">
          {facebook && (
            <a className="text-gray-500" href={facebook}>
              <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
              </svg>
            </a>
          )}
  
          {twitter && (
            <a className="ml-3 text-gray-500" href={twitter}>
              <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
              </svg>
            </a>
          )}
  
          {instagram && (
            <a className="ml-3 text-gray-500" href={instagram}>
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
                <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
              </svg>
            </a>
          )}
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default SingleCard;
