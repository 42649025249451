function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function capitalizeHyphenatedString(str) {
    return str.split('-')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');
  }

  const extractUniqueId = (uniqueIdentifier) => {
    const parts = uniqueIdentifier.split("-");
    const id = parts.pop(); // Extract the ID (last part)
    const name = parts.join(" "); // Rebuild the name from the remaining parts
    return { name, id };
  };

  const extractStateShort = (state) => {
    const stateMatch = state?.match(/,\s([A-Z]{2})\s\d{5},/);
    if (stateMatch) {
      return stateMatch[1]; // Return the state abbreviation
    }
    return null;
  };

  const sortByReviewAndRating = (data) => {
    return data.sort((a, b) => {
      if (b.rating !== a.rating) {
        return b.rating - a.rating; // Sort by rating (descending)
      }
      return b.review_count - a.review_count; // If ratings are equal, sort by review count (descending)
    });
  };

export {capitalizeFirstLetter, capitalizeHyphenatedString, extractUniqueId,extractStateShort,sortByReviewAndRating}