import React from "react";
import { FaSearch } from 'react-icons/fa';


const SearchBar = ({ searchHeader, placeholder, onChange,onKeyPress,value }) => {
  return (
    <div className="w-screen bg-white shadow-md flex p-2 justify-center items-center gap-3 flex-wrap sm:flex-wrap ">
      <p className="font-bold">{searchHeader}</p>
      <div className="flex items-center bg-white border-[1px]">
        <FaSearch className="text-blue-600 mx-2" />
        <input
          type="text"
          placeholder={placeholder}
          className="flex-grow p-2 h-8 text-gray-600 focus:outline-none"
          onChange={onChange}
          onKeyPress={onKeyPress}
          value={value}
        />
      </div>
    </div>
  );
};

export default SearchBar;
